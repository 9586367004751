import React, { type ComponentType } from 'react';
import '@atlaskit/css-reset';
import PageAppInitialisationAnalytics from '@atlassian/jira-common-analytics-page-app-initialisation';
import QueryParamAdapter from '@atlassian/jira-plans-query-param-adapter/src';
import SpaErrorBoundary from '@atlassian/jira-spa-apps-common/src/error-boundary';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { withRouter, type RouteContext } from '@atlassian/react-resource-router';
import Plans from './ui';

export type Props = RouteContext;

export const PlansDirectory = ({ location }: Props) => {
	const tenantContext = useTenantContext();

	return (
		<QueryParamAdapter location={location}>
			{({ query, onQueryChanged }) => (
				<Plans
					// @ts-expect-error - TS2322 - Type '{ tenantContext: TenantContext; query: Query; onQueryChanged: (arg1: Query) => void; }' is not assignable to type 'IntrinsicAttributes & Omit<PropsWithDefault, "Updater">'.
					tenantContext={tenantContext}
					query={query}
					onQueryChanged={onQueryChanged}
				/>
			)}
		</QueryParamAdapter>
	);
};

// @ts-expect-error - TS2322 - Type '{ (props: RouteContext & { history: BrowserHistory; }): Element; displayName: string; }' is not assignable to type 'ComponentType<Record<any, any>>'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RouterWrapper: ComponentType<Record<any, any>> = withRouter(PlansDirectory);
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (props: Record<any, any>) => (
	<UFOSegment name="plans-directory-app">
		<MarkProductStart />
		<SpaErrorBoundary id="spa-app-bootstrap.directories.plans">
			<PageAppInitialisationAnalytics
				appNameSuffixForV2Event="directory.plans"
				appNameForV3OperationalEvent="plansDirectory"
			/>
			<RouterWrapper {...props} />
		</SpaErrorBoundary>
	</UFOSegment>
);
