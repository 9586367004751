import React from 'react';
import defaultsDeep from 'lodash/defaultsDeep';
import noop from 'lodash/noop';
import queryString from 'query-string';
import { RouterActions } from '@atlassian/react-resource-router';
import type { Props } from './model';
import fromUrlTransformerFactory from './transformers/url-to-query';
import { AdapterWithState } from './view';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <TQuery,>({
	location,
	children,
	queryToUrl,
	queryFromUrl,
	defaultQuery,
}: Props<TQuery>) => {
	const toQuery = (search: string): TQuery =>
		defaultsDeep(fromUrlTransformerFactory(queryFromUrl)(queryString.parse(search)), defaultQuery);

	const replaceUrl = (
		// @ts-expect-error - TS2304 - Cannot find name 'Href'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		replace: (path: Href, state?: any) => void,
		query: TQuery,
		prevParams: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[key: string]: any;
		},
	) => replace(`?${queryString.stringify({ ...prevParams, ...queryToUrl(query) })}`, []);

	return (
		<RouterActions>
			{({ replace }) => (
				<AdapterWithState
					search={location.search}
					toQuery={(search) => toQuery(search)}
					onChange={
						__SERVER__
							? noop
							: (
									query: TQuery,
									prevParams: {
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										[key: string]: any;
									},
								) => replaceUrl(replace, query, prevParams)
					}
				>
					{children}
				</AdapterWithState>
			)}
		</RouterActions>
	);
};
