import React, { type ReactNode } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Spinner from '@atlaskit/spinner';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props as CommonProps } from './common/types';

const LazyComponent = lazyForPaint(
	() => import(/* webpackChunkName: "async-portfolio-3-plan-duplicating" */ './index'),
);

type Props = CommonProps & { fallback: ReactNode };

const AsyncComponent = ({ fallback, ...props }: Props) => (
	<JSErrorBoundary
		id="bundle-portfolio-3-plan-duplicating"
		fallback="flag"
		packageName="jira-plan-duplicating"
	>
		<Placeholder
			name="plan-duplicating"
			fallback={fallback !== undefined ? fallback : <Spinner size="large" />}
		>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

AsyncComponent.defaultProps = {
	fallback: undefined,
};

export default AsyncComponent;
