import React from 'react';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { User } from '../../model';
import AvatarTitleCell from '../avatar-title-cell/view.tsx';

export type Props = {
	user: User | null;
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	onClick: () => void | null | undefined;
};

export default function UserCell(props: Props) {
	const { user, onClick } = props;

	if (!user) {
		return null;
	}

	const { accountId, avatarUrl, displayName, profileUrl } = user;

	const content = (
		<AvatarTitleCell
			displayName={displayName}
			avatarUrl={avatarUrl}
			href={profileUrl}
			onClick={onClick}
		/>
	);

	return (
		<AsyncProfileCardNext accountId={accountId} TenantContextSubscriber={TenantContextSubscriber}>
			{content}
		</AsyncProfileCardNext>
	);
}

UserCell.defaultProps = {
	user: null,
	onClick: undefined,
};
