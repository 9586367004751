import React, { type PointerEvent, type ReactElement } from 'react';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import { RouterActions } from '@atlassian/react-resource-router';

export type OnClick = (event: PointerEvent, analyticsEvent: AnalyticsEvent) => void;
export type Props = {
	href: string;
	children: (arg1: { href: string; onClick: OnClick }) => ReactElement;
	onClick: OnClick;
};

export const TransitionComponent = ({ children, onClick, href }: Props) =>
	__SPA__ ? (
		<RouterActions>
			{({ push }) => {
				const clickHandler = (e: PointerEvent, analyticsEvent: AnalyticsEvent) => {
					if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey || e.button === 1) {
						return;
					}

					e.preventDefault();
					onClick && onClick(e, analyticsEvent);
					push(href);
				};
				return children({ href, onClick: clickHandler });
			}}
		</RouterActions>
	) : (
		children({ href, onClick })
	);
