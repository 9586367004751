import type { Reducer, Store } from 'redux';
import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate';
import type { Action } from './actions';
import rootReducer from './reducer';
import type { State } from './reducer/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Action, State };

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <TState, TAction>(rootEpic?: Epic<TAction, TState>): Store<TState> => {
	// @ts-expect-error - TS2322 - Type 'Reducer<unknown>' is not assignable to type 'Reducer<TState>'.
	const fixedTypeRootReducer: Reducer<TState> = rootReducer;

	// @ts-expect-error - TS2344 - Type 'TAction' does not satisfy the constraint '{ type: any; }'.
	return initiateStore<TState, TAction, undefined>({
		appName: 'directory-base',
		rootReducer: fixedTypeRootReducer,
		rootEpic,
	});
};
