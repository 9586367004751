import { stringify } from 'query-string';
import type { User } from '@atlassian/jira-common-directory-v2/src/model';
import { ITEMS_PER_PAGE, NO_JSW_ACCESS_FOR_USER } from '../common/constants';
import type { Plan, PlanUser, Query, PlanStatus } from '../common/types';
import { ACTIVE_STATUS } from '../common/types.tsx';

export type QueryResponse = {
	isLast: boolean;
	maxResults: number;
	startAt: number;
	total: number;
	values: Plan[];
};
export type Result = {
	isError: boolean;
	errorCode?: string;
	result?: QueryResponse;
};

export const filterPlansByQuery = async (query: Query, status?: PlanStatus): Promise<Result> => {
	const queryParams = {
		orderBy: query.sortField,
		sortDirection: query.sortDirection,
		query: query.filter?.text || '',
		maxResults: ITEMS_PER_PAGE,
		startAt: (query.page - 1) * ITEMS_PER_PAGE,
		status: status ?? ACTIVE_STATUS,
	};

	const url = `/rest/jpo/1.0/plans/search?${stringify(queryParams)}`;

	const tryParseJson = (raw: string) => {
		try {
			return JSON.parse(raw);
		} catch (error) {
			return raw;
		}
	};

	const resp = await fetch(url);

	const resText = await resp.text();
	const result = tryParseJson(resText);

	if (!resp.ok) {
		if (resText.includes(NO_JSW_ACCESS_FOR_USER))
			return { isError: true, result, errorCode: NO_JSW_ACCESS_FOR_USER };
		return { isError: true, result };
	}

	return { isError: false, result };
};

export const deletePlan = async (planId: number) => {
	const urlParam = '?enableUndo=false';
	const url = `/rest/jpo/1.0/plans/${planId}${urlParam}`;
	const res = await fetch(url, { method: 'DELETE' });

	return res.json();
};

export const transformPlanUser = (creator: PlanUser): User => {
	const { accountId, avatarUrl, title } = creator;

	return {
		accountId,
		avatarUrl,
		displayName: title,
		profileUrl: `/jira/people/${accountId}`,
	};
};
