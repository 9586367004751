import React, { useCallback } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import ButtonOld from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { useCreateNewPlanWizardDrawer } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/controllers';
import type { createPlanButtonQuery } from '@atlassian/jira-relay/src/__generated__/createPlanButtonQuery.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import messages from './messages';

const CreatePlanButtonOld = () => {
	const [, { openDrawer: openCreateNewPlanWizardDrawer }] = useCreateNewPlanWizardDrawer();
	const { formatMessage } = useIntl();
	const [{ items }] = usePlansItems();
	const isVisible = Boolean(items?.links?.createPlan.isVisible);

	const onClick = useCallback(() => {
		openCreateNewPlanWizardDrawer();
	}, [openCreateNewPlanWizardDrawer]);

	if (!isVisible) return null;

	return (
		<ButtonOld
			appearance="primary"
			onClick={onClick}
			testId="plans-main.ui.content.create-plan-button.button"
		>
			{formatMessage(messages.createPlanButton)}
		</ButtonOld>
	);
};

const CreatePlanButton = () => {
	const [, { openDrawer: openCreateNewPlanWizardDrawer }] = useCreateNewPlanWizardDrawer();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();

	const data = useLazyLoadQuery<createPlanButtonQuery>(
		graphql`
			query createPlanButtonQuery($cloudId: ID!) {
				jira @optIn(to: "JiraAdvancedRoadmapsNavigation") {
					advancedRoadmapsNavigation(cloudId: $cloudId) {
						hasEditPermissions
					}
				}
			}
		`,
		{
			cloudId,
		},
	);

	const onClick = useCallback(() => {
		openCreateNewPlanWizardDrawer();
	}, [openCreateNewPlanWizardDrawer]);

	const isVisible = data.jira?.advancedRoadmapsNavigation?.hasEditPermissions ?? false;

	if (!isVisible) return null;

	return (
		<Button
			appearance="primary"
			onClick={onClick}
			testId="plans-main.ui.content.create-plan-button.button"
		>
			{formatMessage(messages.createPlanButton)}
		</Button>
	);
};

export default componentWithCondition(
	() => getWillShowNav4(),
	CreatePlanButton,
	CreatePlanButtonOld,
);
