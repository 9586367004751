import React, { useEffect } from 'react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalTitle,
	ModalHeader,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { withFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { deletePlan } from '../../../services';
import deletePlanErrorFlag from './flags/error/index.tsx';
import deletePlanSuccessFlag from './flags/success/index.tsx';
import messages from './messages';
import type { Props } from './types';

const DeleteModal = ({
	entityId,
	onCancel,
	onDialogShown,
	onDelete,
	flagService,
	plans,
}: Props) => {
	useEffect(() => {
		onDialogShown();
	}, [onDialogShown]);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const planId = Number(entityId);

	const planTitle = plans.find((plan) => plan.id === planId)?.title || '';

	const confirmPlanDelete = async () => {
		try {
			await deletePlan(planId);
			onDelete();
			flagService.showFlag(deletePlanSuccessFlag(planTitle));

			// Analytics
			const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.DELETE_PLAN.split(' ');
			fireTrackAnalytics(
				createAnalyticsEvent({ action, actionSubject }),
				PRODUCT_ANALYTICS_EVENT_NAMES.DELETE_PLAN,
				{
					date: formatDateUTC(Date.now(), 'YYYY-MM-DD HH:mm:ss'),
				},
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			flagService.showFlag(deletePlanErrorFlag(planTitle));
		}

		// onCancel closes the modal
		onCancel();
	};

	if (!entityId) {
		flagService.showFlag(deletePlanErrorFlag('plan'));
		throw new Error('plan not found');
	}

	return (
		<ShortcutScope>
			<ModalDialog onClose={onCancel}>
				<ModalHeader>
					<ModalTitle appearance="danger">
						{formatMessage(messages.confirmDeletePlanHeading)}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{formatMessage(messages.confirmDeletePlanMessage, {
						title: planTitle,
						b: (chunks: React.ReactNode[]): JSX.Element => <b>{chunks}</b>,
					})}
				</ModalBody>
				<ModalFooter>
					<Button autoFocus appearance="danger" key="confirm" onClick={() => confirmPlanDelete()}>
						{formatMessage(messages.confirm)}
					</Button>
					<Button appearance="subtle" key="cancel" onClick={onCancel}>
						{formatMessage(messages.cancel)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

export default withFlagService(DeleteModal);
